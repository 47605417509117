.Dropzone {
    width: 100%;
    height: 100px;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.42);
    border-style: dashed;
    border-radius: 5px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
