.MenuItem {
    text-transform: uppercase;
}


.MenuItem a {
    display: inline-block;
    text-decoration: none;
    padding: 3px 0;
    margin: 5px 0;
    margin-top: -1px;
    border-bottom: 1px solid rgba(44, 169, 232, 0);
    letter-spacing: 0.15em;
}

.MenuItem a:hover {
    color: #2CA9E8;
    text-decoration: none;
    border-color: rgba(44, 169, 232, 1);
}