.Menu {

}

.MenuWrapper {
    top: 22px;
    right: 20px;
    visibility: hidden;
    opacity: 0;
    background: #FFF;
    z-index: 1;
    -webkit-transition: all .2s linear;
    -moz-transition: all .2s linear;
    -o-transition: all .2s linear;
    transition: all .2s linear;
    position: fixed;
    -webkit-box-shadow: 0px 5px 40px 0px rgba(0,0,0,0.05);
    -moz-box-shadow: 0px 5px 40px 0px rgba(0,0,0,0.05);
    box-shadow: 0px 5px 40px 0px rgba(0,0,0,0.05);
    
}

.User {
    padding: 15px 0 0 0;   
}

.User p {
    margin: 0 60px 0 30px;
    margin-bottom: 0;
}

.User hr {
    margin: 10px 0 5px 0;
}

.MenuItems {
   
}

.MenuItems ul {
    padding: 0 90px 0 30px;
    // padding: 24px 30px 30px;
    list-style: none;
    margin: 0;
    max-height: 94.5vh;
    overflow-y: scroll;
}

.Toggle {
    display: block;
    background: transparent url('../../../assets/images/icon_menu.png') center no-repeat;
    background-size: 32px 26px;
    width: 50px;
    height: 40px;
    cursor: pointer;
    z-index: 9999;
    position: relative;
    -webkit-transition: all .2s linear;
    -moz-transition: all .2s linear;
    -o-transition: all .2s linear;
    transition: all .2s linear;
}

.Toggle__close {
    background-image: url('../../../assets/images/icon_close.png');
}