.ScoresContainer {
    margin-bottom: 1rem;
    overflow: hidden;
    transition: max-height 300ms ease-in-out;

    &.Hidden {
        max-height: 0 !important;
        padding: 0 !important;
    }
}

.ScoresContainer > div {
    line-height: initial;
}

.ScoresContainer p {
    margin-bottom: 0;
    margin-top: 0;
}

.ScoresContainer label {
    text-transform: uppercase;
    color: #003973;
    font-size: 0.6rem;
    vertical-align: bottom;
    margin-bottom: 0;
}

.ScoreToggler {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    color: #003973;
    text-transform: uppercase;

    label {
        cursor: pointer;
    }
}

.Icon {
    transition: transform 300ms;
}

.IconOpen {
    transform: rotate(180deg);
}
