.Container {
    position: relative;
    display: inline-block;
}

.Toggle {
    position: absolute;
    right: 0;
    top: 0.25rem;
    padding: 0 0.25rem;
    cursor: pointer;
    display: none;
}

.Container:hover .Toggle {
    display: initial;
}

.Item {
    cursor: pointer
}