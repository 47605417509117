@import "../../../node_modules/bootstrap//scss/functions/";
@import "../../../node_modules/bootstrap//scss/variables/";
@import "../../../node_modules/bootstrap//scss/mixins/";

.Tab {
  cursor: pointer;
}

.Charts {
  margin-bottom: 1rem;
  height: 400px;
  width: 50%;
}

.ChartsLarge {
  width: 80%;
}

.ChartContainer {
  display: flex;
}

.Sliders {
  overflow: hidden;
  width: 20%;
  margin-top: 1rem;
}

.SliderContainer {
  display: flex;
  margin-bottom: 1rem;

  .Title {
    flex-grow: 0.5;
    flex-basis: 0;

    label {
      font-size: 0.6rem;
      text-transform: uppercase;
      margin: 0;
    }
  
    p {
      margin: 0;
    }
  }

  .Slider {
    flex-grow: 0.7;
    flex-basis: 0;
    margin-right: 1rem;
  }
}

@include media-breakpoint-down(lg) {
  .Charts {
    width: 100%;
  }
}
