.Comment {
    position: relative;
}

.Dropdown {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
}

.Options {
    padding: 0 0.25rem;
    cursor: pointer;
}