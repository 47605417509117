.Container {
  margin: 0.25rem 0 0 0;
  display: flex;
  flex-direction: row;
}

.Container > div {
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.Container > div label {
  text-transform: uppercase;
  font-size: 0.75rem;
  margin:0;
}

.Container > div svg {
  align-self: center;
}

.Container > div+div {
  margin-left: 0.25rem;
}