.root {
    
}

.LogoContainer {
    padding: 12px 24px;
}

.Container {
    padding-top: 1rem;
    padding-bottom: 1rem;
}