@import "custom";
@import "~bootstrap/scss/bootstrap";

* {
    box-sizing: border-box;
}
  
html {
    background-color: #fff;
    padding-top: 96px;
    box-sizing: border-box;
    height: 100%;
}

body {
    font-family: 'AmsiPro','Helvetica Neue',helvetica,arial,sans-serif;
    min-height: 100%;
    background-color: #ffffff;
    background-image: url(../../assets/images/bg.png);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 100% auto;
}

h1, h2, h3, h4, h5 {
    text-transform: uppercase;
    color: $primary;   
}

.content-box {
    background: $white;
    border: 1px solid #dcdcdc;
    padding: 1rem;
    margin: 0;
}

.breadcrumb {
    border: 1px solid #dcdcdc;
    border-radius: 0;
    background: #FFF;
}

.pointer {
    cursor: pointer;
}

.pre {
    white-space: pre-line;
}

.ql-editor {
    min-height: 100px;
}

.unselectable {
    -webkit-user-select: none;
    -moz-user-select: none; 
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}