.CalculationsContainer {
    margin-bottom: 1rem;
}

.CalculationsContainer > div {
    line-height: initial;
}

.CalculationsContainer p {
    margin-bottom: 0;
    margin-top: 0;
}

.CalculationsContainer label {
    text-transform: uppercase;
    color: #003973;
    font-size: 0.6rem;
    vertical-align: bottom;
    margin-bottom: 0;
}