.SliderContainer {
    position: -webkit-sticky;
    position: sticky;
    top: 96px;
    z-index: 9;
    background: #FFF;
    padding: 0.5rem 0;
}

.SliderContainer p { 
    margin-bottom: 0;
}

.SliderContainer > div > div {
    display: flex;
}

.Slider {
    margin: 0rem 1rem 0 1rem;
    flex-grow: 1;
    align-self: center;
}

