.root {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, .9);
    padding: 16px 2% 14px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    height: 96px;
}