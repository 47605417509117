.CalculationsContainer {
    margin-bottom: 1rem;
}

.CalculationsContainer > div {
    line-height: initial;
}

.CalculationsContainer p {
    margin-bottom: 0;
    margin-top: 0;
}

.CalculationsContainer label {
    text-transform: uppercase;
    color: #003973;
    font-size: 0.6rem;
    vertical-align: bottom;
    margin-bottom: 0;
}

.ProductTitle {
    position: -webkit-sticky;
    position: sticky;
    top: 96px; // 136px;
    background: #FFF;
    z-index: 1;
    margin: 0 -1.25rem;
    padding: 0 1.25rem;
}

.ProductDescription {
    min-height: 72px;
}

.Card {
    overflow: hidden;
}

@media (max-width: 768px ) {
    .ProductTitle {
        top: 160px;
    }
}

@media (min-width: 1200px ) {
    .Card {
        margin: 1rem;
    }
}