.Header {
    display: flex;
    justify-content: space-between;
}

.Header h1, .Header h2, .Header h3, .Header h4, .Header h5  {
    display: inline-block;
}

.Actions {
    display: flex;
    align-items: center;
}

.Actions > * {
    margin: 0 0 0 0.5rem;
    padding: 0;
    cursor: pointer;
}